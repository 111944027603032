import CustomButton from '@/components/Button'
import { dashboardColor } from '@/lib/constants/colorConstants'
import {
  Box,
  Icon,
  Text,
  Center,
  VStack,
  useColorMode,
  keyframes,
} from '@chakra-ui/react'
import React from 'react'
import { FaPoop } from 'react-icons/fa'

const spin = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg)}
`

const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); border-radius: 20%; }
  25% { transform: scale(1) rotate(0); border-radius: 20%; }
  50% { transform: scale(1) rotate(10deg); border-radius: 50%; }
  75% { transform: scale(1) rotate(-10deg); border-radius: 50%; }
  100% { transform: scale(1) rotate(0); border-radius: 20%; }
`

const animation = `${animationKeyframes} 2s ease-in-out infinite`

function Cutom404() {
  const { colorMode } = useColorMode()
  const spinAnimation = `${spin} infinite 3s linear`

  return (
    <Box background={dashboardColor[colorMode]} h="100vh">
      <Box maxW="40rem" m="3rem auto">
        <Center>
          <VStack>
            <Text
              fontSize={['5rem', '8rem']}
              textAlign="center"
              color="secondary"
            >
              4
              <Icon animation={spinAnimation} as={FaPoop} />4
            </Text>
            <Text
              fontSize={['1.5rem', '3rem']}
              textAlign="center"
              mb="3rem"
              animation={animation}
            >
              OOPS 😞, THE PAGE YOU ARE LOOKING FOR CAN'T BE FOUND!
            </Text>

            <VStack mt="2rem">
              <CustomButton maxW={['15rem', '20rem']}>
                BACK TO HOMEPAGE
              </CustomButton>
            </VStack>
          </VStack>
        </Center>
      </Box>
    </Box>
  )
}

export default Cutom404
